import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to the Kazio Edition Webpage!</h1>
      </header>
      <body>
        <p>Testing</p>
      </body>
    </div>
  );
}

export default App;
